import React, {useContext} from "react"
import { Link } from "gatsby"
import Template from "../components/template"
import "../styles/startseite.css"
import {startseiteContent} from "../content/startseite"
import {SettingsContext} from "../contexts/settings"
import CoronaInfo from "../components/corona-info"
import LogoDateWimmelbild from "../components/logo-date-wimmelbild"

const StartseitePage = () => {
    const [settings, ] = useContext(SettingsContext)
    const content = startseiteContent[settings.language]
    return (
        <CoronaInfo>
        <Template currentPage="homepage" omitLogo={true}>
            <section>
                <div className="logo-wimmelbild">
                    <Link to="/" className="link-fix">
                        <LogoDateWimmelbild/>
                    </Link>
                </div>
                <div className="wrapper">
                    <img className="wimmelbild" src="/images/Wimmelbild.jpg" alt={content.wimmel}/> 
                    <Link className="barrierefreiheit-container" to="/barrierefreiheit">
                        <div className="element-barrierefreiheit">
                            <img className="image-barrierefreiheit" src="images/wimmelbild/barrierefreiheit.jpg" alt={content.elementBarrierefreiheit}/>
                            <p className="text-barrierefreiheit">{content.barrierefreiheit}</p>
                        </div>
                    </Link>
                    <Link className="blog-container" to="/blog">
                        <div className="element-blog">
                            <img className="image-wimmel-blog" src="images/wimmelbild/blog.jpg" alt={content.elementBlog}/>
                            <p className="text-wimmel-blog">Blog</p>
                        </div>
                    </Link>
                    <Link className="code-container" to="/hilfe">
                        <div className="element-code">
                            <img className="image-code" src="images/wimmelbild/codeofconduct.jpg" alt={content.elementCodeofconduct}/>
                            <p className="text-code">{content.codeofconduct}</p>
                        </div>
                    </Link>
                    <Link className="cooperations-container" to="/kooperationen">
                        <div className="element-cooperations">
                            <img className="image-cooperations" src="images/wimmelbild/cooperations.jpg" alt={content.elementCooperations}/>
                            <p className="text-cooperations">{content.kooperationen}</p>
                        </div>
                    </Link>
                    <Link className="digitales-container" to="/digitales">
                        <div className="element-digitales">
                            <img className="image-digitales" src="images/wimmelbild/digitales.jpg" alt={content.elementDigitales}/>
                            <p className="text-digitales"> {content.digitales}</p>
                        </div>
                    </Link>
                    <Link className="impressum-container" to="/impressum">
                        <div className="element-impressum">
                            <img className="image-impressum" src="images/wimmelbild/impressum.jpg" alt={content.elementImpressum}/>
                            <p className="text-impressum">{content.impressum}</p>
                        </div>
                    </Link>
                    <Link className="projekte-container" to="/projekte">
                        <div className="element-projekte">
                            <img className="image-projekte" src="images/wimmelbild/projekte.jpg" alt={content.elementProjekte}/>
                            <p className="text-projekte">{content.projekte}</p>
                        </div>
                    </Link>
                    <Link className="programm-container" to="/programm">
                        <div className="element-programm">
                            <img className="image-programm" src="images/wimmelbild/programm.jpg" alt={content.elementProgramm}/>
                            <p className="text-programm">{content.programm}</p>
                        </div>
                    </Link>
                    <Link className="teaserprogramm-container" to="/programm">
                        <div className="element-teaserprogramm">
                            <img className="image-teaserprogramm" src="images/wimmelbild/teaserprogramm.jpg" alt={content.elementTeaserprogramm}/>
                            <p className="text-teaserprogramm"> {content.teaserprogramm}</p>
                        </div>
                    </Link>
                    <Link className="team-wimmel-container" to="/team">
                        <div className="element-wimmel-team">
                            <img className="image-wimmel-team" src="images/wimmelbild/team.jpg" alt={content.elementTeam}/>
                            <p className="text-wimmel-team">{content.team}</p>
                        </div>
                    </Link>
                    <a className="tickets-container" href="/startseite" target="_blank" rel="noopener noreferrer">
                        <div className="element-tickets">
                            <img className="image-tickets" src="images/wimmelbild/tickets.jpg" alt={content.elementTickets}/>
                            <p className="text-tickets"> Tickets</p>
                        </div>
                    </a>
                    <Link className="ueber-container" to="/ueber">
                        <div className="element-ueber">
                            <img className="image-wimmel-ueber" src="images/wimmelbild/ueber.jpg" alt={content.elementProgramm}/>
                            <p className="text-wimmel-ueber">{content.ueber}</p>
                        </div>
                    </Link>
                    <Link className="element01-container" to="/veranstaltung/hilma-an-die-noch-nicht-geborenen">
                        <div className="element-01">
                            <img className="image-01" src="images/wimmelbild/01.jpg" alt={content.element01}/>
                            <p className="text-01">HILMA – An die Noch-Nicht-Geborenen</p>
                        </div>
                    </Link>
                    <Link className="element02-container" to="/veranstaltung/nocturnal-queer-bodybuilding">
                        <div className="element-02">
                            <img className="image-02" src="images/wimmelbild/02.jpg" alt={content.element02}/>
                            <p className="text-02">Nocturnal<br/>Queer<br/>Bodybuilding</p>
                        </div>
                    </Link>
                    <Link className="element03-container" to="/veranstaltung/white-cube_dekolonial1">
                        <div className="element-03">
                            <img className="image-03" src="images/wimmelbild/03.jpg" alt={content.element03}/>
                            <p className="text-03">WHITE CUBE_ dekolonial</p>
                        </div>
                    </Link>
                    <Link className="element04-container" to="/veranstaltung/wax-en">
                        <div className="element-04">
                            <img className="image-04" src="images/wimmelbild/04.jpg" alt={content.element04}/>
                            <p className="text-04">WAX-en</p>
                        </div>
                    </Link>
                    <Link className="element05-container" to="/veranstaltung/extinct">
                        <div className="element-05">
                            <img className="image-05" src="images/wimmelbild/05.jpg" alt={content.element05}/>
                            <p className="text-05">Extinct</p>
                        </div>
                    </Link>
                    <Link className="element06-container" to="/veranstaltung/night-song">
                        <div className="element-06">
                            <img className="image-06" src="images/wimmelbild/06.jpg" alt={content.element06}/>
                            <p className="text-06">night<br/>song</p>
                        </div>
                    </Link>
                    <Link className="element07-container" to="/veranstaltung/rundbogen-gegen-schlaflosigkeit">
                        <div className="element-07">
                            <img className="image-07" src="images/wimmelbild/07.jpg" alt={content.element07}/>
                            <p className="text-07">Rundbogen gegen Schlaflosigkeit</p>
                        </div>
                    </Link>
                    <Link className="element08-container" to="/veranstaltung/ranzfurter-hexenkonvent">
                        <div className="element-08">
                            <img className="image-08" src="images/wimmelbild/08.jpg" alt={content.element08}/>
                            <p className="text-08">Ranzfurter<br/>Hexenkonvent</p>
                        </div>
                    </Link>
                    <Link className="element09-container" to="/veranstaltung/pretty-women">
                        <div className="element-09">
                            <img className="image-09" src="images/wimmelbild/09.jpg" alt={content.element09}/>
                            <p className="text-09">Pretty Woman -<br/>That’s not<br/>my name</p>
                        </div>
                    </Link>
                    <Link className="element10-container" to="/veranstaltung/women-walking-and-loitering-at-night">
                        <div className="element-10">
                            <img className="image-10" src="images/wimmelbild/10.jpg" alt={content.element10}/>
                            <p className="text-10">Women Walking and Loitering at Night</p>
                        </div>
                    </Link>
                    <Link className="element11-container" to="/veranstaltung/fetisch-poetische-theorie-performance">
                        <div className="element-11">
                            <img className="image-11" src="images/wimmelbild/11.jpg" alt={content.element11}/>
                            <p className="text-11"><del>FETISCH</del>. poetische theorie performance</p>
                        </div>
                    </Link>
                    <Link className="element12-container" to="/veranstaltung/toss-and-turn">
                        <div className="element-12">
                            <img className="image-12" src="images/wimmelbild/12.jpg" alt={content.element12}/>
                            <p className="text-12">toss and<br/>turn</p>
                        </div>
                    </Link>
                    <Link className="element13-container" to="/veranstaltung/Dosta-Dosta-genug-genug">
                        <div className="element-13">
                            <img className="image-13" src="images/wimmelbild/13.jpg" alt={content.element13}/>
                            <p className="text-13">Dosta, Dosta <nobr>(genug, genug)</nobr></p>
                        </div>
                    </Link>
                    <Link className="element14-container" to="/veranstaltung/the-hidden-side-of-the-moon/">
                        <div className="element-14">
                            <img className="image-14" src="images/wimmelbild/14.jpg" alt={content.element14}/>
                            <p className="text-14">The Hidden<br/>Side of<br/>the Moon</p>
                        </div>
                    </Link>
                    <Link className="element15-container" to="/veranstaltung/operation-alien-love-1">
                        <div className="element-15">
                            <img className="image-15" src="images/wimmelbild/15.jpg" alt={content.element15}/>
                            <p className="text-15">Operation Alien Love #1</p>
                        </div>
                    </Link>
                    <Link className="element16-container" to="/veranstaltung/schleim-is-part-of-the-answer">
                        <div className="element-16">
                            <img className="image-16" src="images/wimmelbild/16.jpg" alt={content.element16}/>
                            <p className="text-16">SCHLEIM IS (PART OF) THE ANSWER</p>
                        </div>
                    </Link>
                    <Link className="element17-container" to="/veranstaltung/feel-tank-feminist-burnout">
                        <div className="element-17">
                            <img className="image-17" src="images/wimmelbild/17.jpg" alt={content.element17}/>
                            <p className="text-17">Feel Tank: Feminist Burnout</p>
                        </div>
                    </Link>
                    <Link className="element18-container" to="/veranstaltung/femmag">
                        <div className="element-18">
                            <img className="image-18" src="images/wimmelbild/18.jpg" alt={content.element18}/>
                            <p className="text-18">Fem.Mag</p>
                        </div>
                    </Link>
                    <Link className="element19-container" to="/veranstaltung/documenting-nocturnal-flaneuseries">
                        <div className="element-19">
                            <img className="image-19" src="images/wimmelbild/19.jpg" alt={content.element19}/>
                            <p className="text-19">Documenting Nocturnal Flâneuseries</p>
                        </div>
                    </Link>
                    <Link className="element20-container" to="/veranstaltung/a-pocket-of-dyke-feelings">
                        <div className="element-20">
                            <img className="image-20" src="images/wimmelbild/20.jpg" alt={content.element20}/>
                            <p className="text-20">A Pocket full of Dyke Feelings</p>
                        </div>
                    </Link>
                    <Link className="element21-container" to="/veranstaltung/ausstellen-einstellen">
                        <div className="element-21">
                            <img className="image-21" src="images/wimmelbild/21.jpg" alt={content.element21}/>
                            <p className="text-21">Ausstellen Einstellen</p>
                        </div>
                    </Link>
                    <Link className="element2223-container" to="/veranstaltung/dark-matters">
                        <div className="element-2223">
                            <img className="image-2223" src="images/wimmelbild/2223.jpg" alt={content.element2223}/>
                            <p className="text-2223">d ark m atters x a chaotic happening</p>
                        </div>
                    </Link>
                    <Link className="element24-container" to="/veranstaltung/i-dreamt-a-little-dream">
                        <div className="element-24">
                            <img className="image-24" src="images/wimmelbild/24.jpg" alt={content.element24}/>
                            <p className="text-24">I dreamt a little dream</p>
                        </div>
                    </Link>
                    <Link className="element25-container" to="/veranstaltung/edit-a-night">
                        <div className="element-25">
                            <img className="image-25" src="images/wimmelbild/25.jpg" alt={content.element25}/>
                            <p className="text-25">Edit-a-Night</p>
                        </div>
                    </Link>
                    <Link className="element26-container" to="/veranstaltung/not-another-vampire-movie1">
                        <div className="element-26">
                            <img className="image-26" src="images/wimmelbild/26.jpg" alt={content.element26}/>
                            <p className="text-26">Not another<br/>vampire movie</p>
                        </div>
                    </Link>
                    <Link className="element27-container" to="/veranstaltung/the-strange-half-absence-of-walking-at-night">
                        <div className="element-27">
                            <img className="image-27" src="images/wimmelbild/27.jpg" alt={content.element27}/>
                            <p className="text-27">The Strange Half-Absence of Wandering at Night</p>
                        </div>
                    </Link>
                    
                </div>
            </section>
        </Template>
        </CoronaInfo>
    )
}
    
export default StartseitePage
const coronaInfoContent = {
    german: {
        corona: "Corona-Information",
        information: "Die Corona-Pandemie hat es uns nicht leicht gemacht zu planen! Bis vor kurzer Zeit hatten wir gehofft, dass es die Infektions-Zahlen Ende Mai ermöglichen, einen Teil des Programms von Nocturnal Unrest vor Ort stattfinden zu lassen. Angesichts der aktuellen Entwicklungen der Corona-Pandemie wird das Festival nun zum allergrößten Teil digital stattfinden! Mehr zum aktuellen Stand findest du unter ",
        informationLink: "Programm",
    
    },
    english: {
        corona: "Corona information",
        information: "The Corona pandemic has complicated planning a lot! We have put a lot of hope into that the infection figures at the end of May will make it possible to have the Nocturnal Unrest programme take place locally. We have now decided, that we need to re-locate the festival into digital spheres. Find out more about the state of the arts at ",
        informationLink: "Programme",
    }
}
export {coronaInfoContent}
import React, { useEffect, useState, useContext } from "react"
import { Link } from "gatsby"
import "../styles/corona-info.css"
import { coronaInfoContent } from "../content/corona-info" 
import { SettingsContext } from "../contexts/settings"


const CoronaInfo = ({children}) => {
    const [isInitialLoad, setIsInitialLoad] = useState(true)
    const [showCoronaInfo, setShowCoronaInfo] = useState(true)
    const [settings, ] = useContext(SettingsContext)
    const content = coronaInfoContent[settings.language]

    useEffect(
        () => {
            if (!isInitialLoad) {
                localStorage.setItem("showCoronaInfo", showCoronaInfo)
                return
            }

            const storedShowCoronaInfo = localStorage.getItem("showCoronaInfo")
            if (storedShowCoronaInfo !== null) {
                setShowCoronaInfo(JSON.parse(storedShowCoronaInfo))
            }
            setIsInitialLoad(false)
        },
        [isInitialLoad, showCoronaInfo]
    )

    const ariaHidden = !showCoronaInfo ? { "aria-hidden": true } : {}

    return (
        <div className="corona-info-anchor">
            <div className={`corona-info ${!showCoronaInfo ? "hidden" : ""}`} {...ariaHidden}>
                <div className="corona-info-text">
                    <h1>{content.corona}</h1>
                    <p>
                        {content.information}
                        <Link className="link" to="/programm">{content.informationLink}</Link>.
                    </p>
                </div>
                <button className="corona-info-button" onClick={() => setShowCoronaInfo(false)}>
                    x
                </button>
            </div>
            {children}
        </div>
    )
}

export default CoronaInfo
import React from "react"

const  LogoDateWimmelbild = () => (
    <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1537.37 130.68">
        <title>"index Nocturnal Unrest"</title>
        <path d="M224.06,140.69V158h.72a39.17,39.17,0,0,1,16.82-15.73,48.65,48.65,0,0,1,21.14-4.93q13.68,0,22.46,3.73A33.3,33.3,0,0,1,299,151.38a40,40,0,0,1,7.08,16.09,100.17,100.17,0,0,1,2.05,21v76.39H274V194.73q0-15.36-4.81-22.94t-17.05-7.56q-13.93,0-20.18,8.28t-6.25,27.27v65.1H191.63V140.69Z" transform="translate(-191.63 -137.32)"/>
        <path d="M1694.89,137.32v68.46q0,19-6.24,27.27t-20.18,8.29q-12.26,0-17.06-7.57t-4.8-22.94V137.32H394.37A70.74,70.74,0,0,0,368,142a56.77,56.77,0,0,0-33.15,33.87,76.06,76.06,0,0,0-4.57,27,75,75,0,0,0,4.57,26.9,58.1,58.1,0,0,0,13,20.54A56.57,56.57,0,0,0,368,263.43,72.39,72.39,0,0,0,394.37,268H1657.9a49.71,49.71,0,0,0,21.14-4.8,38.41,38.41,0,0,0,16.81-15.62h.72v17.3H1729V137.32Z" transform="translate(-191.63 -137.32)"/>
        <text y="95" x="1085" font-family="HK Grotesk" font-size="85px" font-weight="300" fill="#ff1cff" text-anchor="middle" text-decoration="none">
            20.05.-24.05.2021
        </text>
    </svg>
)

export default LogoDateWimmelbild
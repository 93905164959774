const startseiteContent = {
    german: {
        wimmel: "Wimmelbild mit verschiedenen Charakteren in einer Stadt, die stellvertretend je für die beitragenden Projekte steht. Diese sind im Bild verlinkt.",
        element01: "Seite \"HILMA – An die Noch-Nicht-Geborenen\"",
        element02: "Seite \"Nocturnal Queer Bodybuilding\"",
        element03: "Seite \"WHITE CUBE_dekolonial\"",
        element04: "Seite \"Wax-en\"",
        element05: "Seite \"Extinct\"",
        element06: "Seite \"Night Song\"",
        element07: "Seite \"Schlaflos\"",
        element08: "Seite \"Ranzfurter Hexenkonvent\"",
        element09: "Seite \"Pretty Woman - That's not my name\"",
        element10: "Seite \"Neha Singh: Eröffnungsrede: Women Walking and Loitering at Night\"",
        element11: "Seite \"Fetisch\"",
        element12: "Seite \"toss and turn\"",
        element13: "Seite \"Dosta dosta (genug, genug)\"",
        element14: "Seite \"The Hidden Side of the Moon: Towards a Queer-Feminist Dream Interpretation\"",
        element15: "Seite \"Because the night belongs to others\"",
        element16: "Seite \"Schleim is (part of) the answer\"",
        element17: "Seite \"Feel Tank #1: Feminist Exhaustion\"",
        element18: "Seite \"FemMag\"",
        element19: "Seite \"Documenting Nocturnal Flâneuseries\"",
        element20: "Seite \"A Pocket of Dyke Feelings\"",
        element21: "Seite \"Ausstellen Einstellen?\"",
        element2223: "Seite \"Dark Matters\"",
        element24: "Seite \"I dreamt a little dream\"",
        element25: "Seite \"Edit-a-night\"",
        element26: "Seite \"Not another vampire movie\"",
        element27: "Seite \"The Strange Half-Absence of Walking at Night\"",
        elementBarrierefreiheit: "Seite \"Barrierefreiheit\"",
        elementBlog: "Seite Blog",
        elementCodeofconduct: "Seite \"Hilfe und Awareness\"",
        elementCooperations: "Seite \"Partner:innen\"",
        elementDigitales: "Seite \"Digitales\"",
        elementImpressum: "Seite \"Impressum\"",
        elementProjekte: "Seite \"Projekte\"",
        elementProgramm: "Seite \"Programm\"",
        elementTeam: "Seite \"Team und Kontakt\"",
        elementTeaserprogramm: "Seite \"Teaserprogramm\"",
        elementTickets: "Seite \"Ticketshop Mousonturm\"",
        barrierefreiheit: "Barriere-freiheit",
        codeofconduct: "Hilfe und Awareness",
        digitales: "Digitales",
        impressum: "Impressum",
        projekte: "Projekte",
        team: "Team und Kontakt",
        teaserprogramm: "Teaser-programm",
        kooperationen: "Partner:innen",
        programm: "Programm",
        ueber: "Über",
        altLogoDate: "Startseite Nocturnal Unrest",

        
    },
    english: {
        wimmel: "Hidden object picture with different characters in a city that represents each of the contributing projects. These are linked in the picture.",
        element01: "page HILMA – An die Noch-Nicht-Geborenen",
        element02: "page \"Nocturnal Queer Bodybuilding\"",
        element03: "page \"WHITE CUBE_dekolonial\"",
        element04: "page \"Wax-en\"",
        element05: "page \"Extinct\"",
        element06: "page \"Night Song\"",
        element07: "page \"Schlaflos\"",
        element08: "page \"Ranzfurter Hexenkonvent\"",
        element09: "page \"Pretty Woman - That's not my name\"",
        element10: "page \"Neha Singh: Opening Lecture: Women Walking and Loitering at Night\"",
        element11: "page \"Fetisch\"",
        element12: "page \"toss and turn\"",
        element13: "page \"Dosta dosta (genug, genug)\"",
        element14: "page \"The Hidden Side of the Moon: Towards a Queer-Feminist Dream Interpretation\"",
        element15: "page \"Because the night belongs to others\"",
        element16: "page \"Schleim is (part of) the answer\"",
        element17: "page \"Feel Tank #1: Feminist Exhaustion\"",
        element18: "page \"FemMag\"",
        element19: "page \"Documenting Nocturnal Flâneuseries\"",
        element20: "page \"A Pocket of Dyke Feelings\"",
        element21: "page \"Ausstellen Einstellen?\"",
        element2223: "page \"Dark Matters\"",
        element24: "page \"I dreamt a little dream\"",
        element25: "page \"Edit-a-night\"",
        element26: "page \"Not another vampire movie\"",
        element27: "page \"The Strange Half-Absence of Walking at Night\"",
        elementBarrierefreiheit: "page \"accessibility\"",
        elementBlog: "page blog",
        elementCodeofconduct: "page \"Help and Awareness\"",
        elementCooperations: "page \"partners\"",
        elementDigitales: "page \"digital\"",
        elementImpressum: "page \"legal notice\"",
        elementProgramm: "page \"programme\"",
        elementTeam: "page \"team and contact\"",
        elementTeaserprogramm: "page \"teaser program\"",
        elementTickets: "page \"ticket shop Mousonturm\"",
        barrierefreiheit: "Accessi-bility",
        codeofconduct: "Help and Awareness",
        digitales: "Digital",
        impressum: "Legal Notice",
        projekte: "Projects",
        team: "Team and Contact",
        teaserprogramm: "Teaser Programme",
        kooperationen: "Partners",
        programm: "Programme",
        ueber: "About",
        altLogoDate: "home page Nocturnal Unrest",
        
    }
}
export {startseiteContent}